/**
 * Timetastic
 * 404 page
 */
import React, { useEffect } from "react"
import { Link } from "gatsby"

// Helpers
import { animate, trackCta } from "src/helpers"

// Components
import Footer from "src/components/footer"
import FooterCta from "src/components/footerCta"
import Header from "src/components/header"
import Layout from "src/components/layout"
import SEO from "src/components/seo"

// JSX
const Default = () => {
  // Animate any elements with a class of 'is-unanimated'
  useEffect(() => {
    animate()
  }, [])

  return (
    <>
      <SEO title="Content not found" />
      <Layout>
        <Header />
        <FooterCta />
        <main>
          <div className="u-inner">
            <section className="c-section">
              <h1 className="h h1">
                Well, it looks like that page is on annual leave!
              </h1>
              <p>OK, might be best if you go to our</p>
              <p>
                <Link
                  to="/"
                  className="c-button"
                  onClick={() => {
                    trackCta("404Home")
                  }}
                >
                  Home page
                </Link>
              </p>
            </section>
          </div>
        </main>
        <Footer />
      </Layout>
    </>
  )
}
export default Default
